export default {

    // The main header on the page
    header: "Mr. Bullsec",

    // The links that will be displayed as buttons on the main page
    links: [
        {
            text: "Bullsec Blog",
            url: "https://blog.bullsec.xyz"
        },
        {
            text: "Hacking Notes",
            url: "https://notes.bullsec.xyz"
        }
    ],

    // The social media buttons displayed at the bottom of the page
    // The key is used to determine the icon, located in the public/icons folder
    social: [
        {
            key: "twitter",
            url: "https://twitter.com/bullsecsecure"
        },
        {
            key: "github",
            url: "https://www.github.com/bull-sec"
        },
        {
            key: "youtube",
            url: "https://www.youtube.com/"
        },
        // {
        //     key: "linkedin",
        //     url: "https://www.linkedin.com/"
        // },
        // {
        //     key: "instagram",
        //     url: "https://www.instagram.com/"
        // },
        // {
        //     key: "stackoverflow",
        //     url: "https://www.stackoverflow.com/"
        // },
        // {
        //     key: "facebook",
        //     url: "https://www.facebook.com/"
        // },
    ]
}